// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return intFrameWidth <= 767 ? true : false;
}

// ==================================================
//  スクロールイベント
//    - 追従ヘッダーの横スクロール対応（PC）
// ==================================================
const header = document.querySelector(".header-fixed");
let scrollTicking = false;
document.addEventListener(
  "scroll",
  (event) => {
    if (!scrollTicking && header !== null) {
      requestAnimationFrame(() => {
        scrollTicking = false;
        header.style.left = -window.scrollX + "px";
      });
      scrollTicking = true;
    }
  },
  { passive: true }
);

// ==================================================
//  ヘッダーメニューボタン（SP）
// ==================================================
const menu = document.querySelector(".js-headerMenu");
const menuActiveClass = "js-headerMenu-active";
const menuButton = document.querySelector(".js-headerMenuButton");
const menuBUttonActiveClass = "js-headerMenuButton-active";
function menuToggle() {
  menu.classList.toggle(menuActiveClass);
  menuButton.classList.toggle(menuBUttonActiveClass);
}
menuButton.addEventListener("click", (event) => {
  menuToggle();
});
